@import "../../common/styles/colors.css";

.auth-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.auth-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.auth-sign-up-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.auth-sing-up-name-lastname {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.auth-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.auth-title {
  font-size: 32px !important;
  line-height: 40px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Sora";
  justify-content: center;
  color: #ffffff;
  margin-bottom: 16px;
}
.input-label {
  font-family: "Sora" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #ffffff !important;
}
.auth-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-bottom: 64px;
  text-align: center;
}

.auth-services-button-container {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
}

.auth-services-button-container button {
  background-color: rgb(0, 0, 0, 23%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: unset;
}

.auth-services-button-container .firstButton {
  margin-right: 12px;
}

.auth-services-button-container .secondButton {
  margin-left: 12px;
}

.auth-services-button-container button p {
  margin-left: 12px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
}

.auth-divider {
  margin-top: 28px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.auth-line {
  width: 100%;
  height: 1px;
  border: 0;
}

.auth-line hr {
  width: 100%;
  height: 2px;
  border: 0;
  background-color: rgb(0, 0, 0, 23%);
}

.auth-text {
  color: rgb(0, 0, 0, 23%);
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin: 0 10px;
}

.auth-input {
  background-color: var(--white-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px;
  border: none;
  border-radius: 10px;
  margin-top: 16px;
}

.auth-services-container {
  display: flex;
  flex-direction: row;
}

.auth-switch-button {
  text-decoration: underline !important;
  color: #ffa654;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.auth-input-login-container {
  display: flex;
  flex-direction: column;
}

.auth-submit-button {
  margin-top: 16px !important;
}

.auth-redirect {
  margin-top: 14px;
  font-size: 14px;
  line-height: 120%;
  font-weight: 400;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider {
  border-top: 1px solid #0c116b !important;
  width: 100%;
  margin: 0 !important;
}
.auth-redirect a {
  text-decoration: underline !important;
  color: #ffa654;
  margin: 16px 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.auth-error-message {
  color: var(--red-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 21px;
}
