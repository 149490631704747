@import "./colors.css";

.btn {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  border-radius: 4px;
  width: 100%;
  margin: 32px 0px 0;
}

.super-large {
  height: 49px;
}

.large {
  width: 196px;
  height: 40px;
}
.large2 {
  width: 196px;
  height: 40px;
  font-weight: 500 !important;
}
.primary {
  background-color: var(--violet-color);
  color: var(--white-color);
}
.yellow {
  background-color: var(--yellow-color);
  color: var(--dark-blue-color);
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem !important;
}

.secondary {
  background-color: var(--white-color);
  color: var(--violet-color);
  border: 2px solid var(--violet-color);
}

.warning-red {
  background-color: var(--red-color-button);
  color: var(--white-color);
}

.warning-red:disabled {
  background: #dddddd;
}
