.logo {
  padding: 40px 0px 0px 40px;
  align-self: flex-start;
}

.iconography {
  width: 100%;
  margin: 0 auto;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 57%;
  height: 100%;
}
.right {
  width: 43%;
  height: 100%;
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  padding: 0 5%;
}

.verify-form {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.verify-container-spinner {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
}
.verify-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.verify-container > h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 18px;
}

.verify-container > p {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
}

.verify-container p:last-child {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
}

.verify-body {
  display: flex;
  flex-direction: row;
  padding: 0px 40px;
  align-items: center;
}

.iconography-wrapper {
  display: flex;
  width: 70%;
  max-height: 100%;
  align-content: center;
}
.verify-inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.verify-input {
  background-color: var(--white-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px;
  border: none;
  border-radius: 10px;
  margin-top: 16px;
  width: 100%;
  margin-bottom: 24px;
}

.email-input {
  background-color: var(--white-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px;
  border: none;
  border-radius: 10px;
  margin-top: 16px;
  width: 100%;
  margin-bottom: 0px;
}
