@import "colors.css";

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

body {
  background-color: var(--grey-bg-color);
  font-family: Poppins, Roboto, Helvetica, Arial, sans-serif;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
