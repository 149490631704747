.outer {
  display: flex;
  flex-direction: row;
}

.nav-container {
  width: 250px;
}

.body-container {
  padding: 48px 64px;
  width: 100%;
}

.body-container-noPadding {
  width: 100%;
  padding: 0px;
}
