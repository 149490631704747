:root {
  --grey-bg-color: #f7f8fc;
  --dark-blue-color: #010032;
  --violet-color: #531da7;
  --yellow-color: #ffa654;
  --dark-grey-bg-color: #6c6c6c;
  --white-color: #ffffff;
  --black-color: #000000;
  --grey-font-color: #555555;
  --grey-input-font-color: #52565b;
  --violet-icon-color: #7721ff;
  --red-color: #ff4f5e;
  --grey-light: #595959;
  --red-color-button: #e43d3d;
  --green-color-active-link: #42ffb0;
  --blue-color-link: #0c50ff;
  --blue-color-button: #3f51b5;
}
