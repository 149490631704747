@import '/src/common/styles/colors.css';

/* .outer-modal {
  position: fixed;
  z-index: 500;
  background-color: var(--white-color);
  width: 70%;
  padding: 16px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  border-radius: 20px;
} */

.outer-modal-spinner {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  /* background-color: white; */
  /* background-color: rgba(255, 255, 255, 0.7); */
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s ease-out;
  padding-top: 0px;
}
.outer-modal-with-bg {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: 0.5s;
  overflow: auto;
  transition: all 0.3s ease-out;
  padding-top: 0px;
}
