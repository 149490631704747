.logo {
  padding: 40px 0px 0px 40px;
}
.image-container {
  background-image: url("/public/home-page-background.svg");
  object-fit: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: bottom;
}
.iconography {
  width: 100%;
  margin: 0 auto;
}

.reset-body {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100vh;
}

.reset-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.reset-input {
  background-color: var(--white-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px;
  border: none;
  border-radius: 10px;
  margin-top: 16px;
  width: 100%;
  margin-bottom: 24px;
}

.email-input {
  background-color: var(--white-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding: 12px;
  border: none;
  border-radius: 10px;
  margin-top: 16px;
  width: 100%;
  margin-bottom: 0px;
}

.logo {
  padding: 40px 0px 0px 40px;
}

.iconography {
  width: 100%;
  margin: 0 auto;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 57%;
  height: 100%;
}
.right {
  width: 43%;
  height: 100%;
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  padding: 0 5%;
}

.iconography-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.iconography-wrapper {
  display: flex;
  width: 70%;
  max-height: 100%;
  align-content: center;
}
.reset-container {
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: space-around;
}

/* .auth-wrapper {
  width: 43%;
  height: 100%;
  background-color: #ffffff;
  justify-content: center;
  padding: 0 5%;
} */
.reset-icon {
  cursor: pointer;
}

.reset {
  margin-top: 15px !important;
}

.reset-password-rules {
  margin-top: 12px;
}

.reset-password-rules ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 26px;
  letter-spacing: 0.46px;
}

.valid {
  color: green;
}
.not-valid {
  color: red;
}

.reset-align-right {
  text-align: right;
  margin-top: 55px;
}

.reset-header {
  margin-top: 37px;
}

.reset-description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  margin-top: 16px;
}
